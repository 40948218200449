.arhiv {
  margin: 0 auto;
  width: 80%;
}

.arhiv-item {
  margin: 5px auto;
  width: inherit;
  height: inherit;
}

.arhiv-item img {
  width: 100%;
  height: 100%;
}

@media (max-width: 705px) {
  .arhiv {
    margin: 0 auto;
    width: 90%;
  }
}

@media (max-width: 500px) {
  .arhiv {
    margin: 0 auto;
    width: 95%;
  }
}