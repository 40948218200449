.testCard {
  width: 80%;
  margin: 0 auto;
  border: 1px solid var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
}

.variants {
  list-style: none;
  margin: 0;
  padding: 0;
}

.buttons {
  margin: 10px auto;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.button {
  border: 1px solid var(--sub-adaptive-color);
  background-color: var(--bg-color);
  border-radius: 5px;
  padding: 5px 15px;
  text-align: center;
  cursor: pointer;
  color: var(--text-color);
  width: auto;
}

.button:hover {
  background-color: var(--sub-nav-bg-color);
}

.button a {
  text-decoration: none;
  color: var(--text-color);
}

.test-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.result-btn {
  width: 50%;
  margin-top: 5px;
}

.result-btn-disabled {
  cursor: default;
  background-color: var(--sub-nav-bg-color);
}

@media (max-width: 705px) {
  .testCard {
    width: 95%;
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    width: 90%;
  }

  .buttons .button {
    width: auto;
  }

  .test-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .result-btn {
    width: 95%;
  }
}