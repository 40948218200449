.card {
  width: 95%;
  margin: 0 auto;
  border: 1px solid var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

.button {
  border: 1px solid var(--sub-adaptive-color);
  background-color: var(--bg-color);
  border-radius: 5px;
  padding: 5px 15px;
  text-align: center;
  cursor: pointer;
}

.button:hover {
  background-color: var(--sub-nav-bg-color);
}

.button a {
  text-decoration: none;
  color: var(--text-color);
}

.result-card {
  justify-content: center;
  width: 50%;
}

@media (max-width: 705px) {
  .card {
    width: 95%;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .button {
    width: 70%;
    margin-top: 5px;
    padding: 5px;
    margin-bottom: 15px;
  }
}