:root {
  --bg-color: #424530;
  --text-color: #FFEFCD;
  --border-color: #E09132;
  --sub-nav-bg-color: rgba(66, 69, 48, 0.8);
  --sub-adaptive-color: #A58E74;
}

.header {
  height: 48px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.menu {
  margin: 0 auto;
  width: 70%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin: 0 10px;
}

.link-logo {
  text-decoration: none;
  color: var(--text-color);
  border-bottom: 2px solid var(--bg-color);
}

.nav-items {
  display: flex;
}

.nav-item {
  list-style: none;
  margin: 0 10px;
}

.nav-link {
  text-decoration: none;
  color: var(--text-color);
  border-bottom: 2px solid var(--bg-color);
}

.nav-link:hover {
  border-bottom: 2px solid var(--border-color);
}

.burger-btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  display: none;
}

.nav-item-icon {
  margin-left: 5px;
}

.sub-nav {
  position: absolute;
  margin: 0;
  padding-top: 20px;
}

.sub-nav-hidden {
  display: none;
}

.sub-nav-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  background-color: var(--sub-nav-bg-color);
  border-radius: 5px;
  margin: 0;
  padding: 10px;
  row-gap: 10px;
}

.sub-nav-item {
  list-style: none;
  margin-bottom: 5px;
}

.sub-nav-link {
  text-decoration: none;
  color: var(--text-color);
  border-bottom: 2px var(--sub-nav-bg-color);
}

.sub-nav-link:hover {
  border-bottom: 2px solid var(--border-color);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%
}

@media (max-width: 1340px) {
  .menu {
    width: 80%;
  }
}

@media (max-width: 1170px) {
  .menu {
    width: 90%;
  }
}

@media (max-width: 1105px) {
  .menu {
    width: 95%;
  }
}

@media (max-width: 1017px) {
  .menu {
    width: 100%;
    /* justify-content: flex-start; */
    margin: 0;
  }

  .logo {
    margin: 0;
    margin-left: 5px;
  }

  .nav-items {
    padding: 0;
  }
}

@media (max-width: 880px) {
  .menu {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    width: 100%;
  }

  .nav-items {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav-item {
    text-align: center;
    margin: 10px 0;
  }

  .nav {
    display: none;
    position: fixed;
    top: 35px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-color);
  }

  .sub-nav {
    position: unset;
    padding-top: 3px;
  }

  .sub-nav-items {
    display: flex;
    margin: 0;
    padding: 3px;
    flex-direction: column;
    width: 100%;
    border-radius: 0px;
    background-color: var(--sub-adaptive-color);
  }

  .sub-nav-item {
    margin: 10px 0;
  }

  .burger-btn {
    display: block;
    margin-top: 15px;
  }

  .logo {
    margin-top: 15px;
  }

  .active {
    display: flex;
  }
}