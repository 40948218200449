.about-top {
  text-align: center;
  margin: 0 auto;
}

.container-about {
  margin: 0 auto;
  width: 70%;
}

.about-img {
width: 100%;
height: 100%;
}

.about-content p, .about-content h3{
  width: 95%;
}

.about-img-container {
  float: left;
  margin-right: 10px;
  width: 250px;
  height: 500px;
}

@media (max-width: 892px) {
  .container-about {
    width: 90%;
  }
}

@media (max-width: 795px) {
  .container-about {
    width: 100%;
  }
}

@media (max-width: 705px) {
  .about-img-container{
    float: none;
    margin: 0 auto;
    width: 250px;
    height: 550px;
  }

  .about-content p,h3 {
    margin-left: 15px;
  }
}